'use client';

import React, { useCallback, useState } from 'react';

import { throttle } from 'lodash';
import { type NextPage } from 'next';
import { useRouter } from 'next/navigation';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { toast } from 'react-toastify';

import Layout from '@/components/Layout';
import Spinner from '@/components/svg/spinner';
import { SuppliersService } from '@/services/suppliers';

import { version } from '../../package.json';

const Landing: NextPage = (): JSX.Element => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [supplierItems, setSupplierItems] = useState<SupplierItem[]>([]);
  const [supplierName, setSupplierName] = useState<string>();
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierItem | undefined>();
  const user = localStorage.getItem('user');
  if (user) {
    router.push('/orders');
    return;
  }

  const { mutateAsync: searchAutocomplete } = SuppliersService.useSearchAutocomplete();

  const isValidSupplier = () => {
    return supplierName?.trim()?.length && supplierName.length >= 3;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (isValidSupplier()) {
      router.push(
        `/auth/steptwo?supplierExist=${
          selectedSupplier?.id || ''
        }&userCount=${selectedSupplier?.user_count}&supplierName=${supplierName}`,
      );
    } else {
      toast.info('Debes llenar el nombre de tu empresa');
      setLoading(false);
    }
  };

  const handleOnClear = () => {
    setSupplierName('');
  };

  const handleGoToLogin = () => {
    router.push('/auth/login');
  };

  const handleGoToJoin = () => {
    router.push('/auth/join');
  };

  const handleSetitems = (data: any[]) => {
    setSupplierItems(data);
  };

  const handleOnSelect = (result: SupplierItem) => {
    setSupplierName(result.name);
    setSelectedSupplier(result);
  };

  const throttled = useCallback(
    throttle(res => {
      handleSetitems(res.data);
    }, 50),
    [supplierName],
  );

  const loadOptions = (supplierName: string) => {
    setSupplierName(supplierName);
    searchAutocomplete({ search: supplierName }).then((res: any) => {
      const supplierAlreadyExists = res.data.findIndex((val: SupplierItem) => val.name === supplierName);
      if (supplierAlreadyExists === -1) {
        setSelectedSupplier(undefined);
      } else {
        setSelectedSupplier(res.data[supplierAlreadyExists]);
      }
      throttled(res);
    });
  };

  return (
    <Layout>
      <div className="flex flex-col text-center space-y-6 pb-10">
        <span className="text-8xl font-extrabold">Alisto</span>
        <span className="max-w-md">
          Proveedores usan Alisto para crecer su negocio sin costos extras conectando con clientes que buscan tus
          ingredientes
        </span>
        <div className="py-6 px-11 max-w-md bg-white shadow-md drop-shadow-md flex items-center space-x-4">
          <div className="max-w-md w-full space-y-10">
            <div>
              <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">Únete a Alisto</h2>
            </div>
            <form className="mt-8 space-y-3" onSubmit={handleSubmit} autoComplete={'off'}>
              <div className="space-y-6">
                <div>
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div style={{ zIndex: 2 }}>
                      <label className="mb-2" htmlFor="company_name">
                        Nombre de la empresa
                      </label>
                      <ReactSearchAutocomplete<SupplierItem>
                        items={supplierItems}
                        onSearch={loadOptions}
                        // onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        // onFocus={handleOnFocus}
                        onClear={handleOnClear}
                        autoFocus
                        styling={{
                          zIndex: 4,
                          height: '60px',
                          border: 'unset',
                          borderRadius: '0px',
                          backgroundColor: 'rgb(234, 234, 234)',
                          boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
                          hoverBackgroundColor: '#eee',
                          color: '#212121',
                          fontSize: '16px',
                          fontFamily: 'Arial',
                          iconColor: 'grey',
                          lineColor: 'rgb(232, 234, 237)',
                          placeholderColor: 'grey',
                          clearIconMargin: '3px 14px 0 0',
                          searchIconMargin: '0 0 0 16px',
                        }}
                        showNoResults={false}
                        // formatResult={formatResult}
                      />
                    </div>
                  </div>
                  <div className="text-xs text-center mt-2">
                    Si tu empresa ya existe aparecerá cuando escribas su nombre
                  </div>
                </div>
              </div>

              <div className="flex pt-6 justify-center">
                <button
                  type="submit"
                  disabled={loading || !isValidSupplier()}
                  style={{ zIndex: 0 }}
                  className="w-10/12 group relative w-full flex justify-center py-2 px-4 border border-transparent text-2xl font-medium rounded-md text-white bg-brand-500 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand-500 text-white items-center disabled:bg-lightgrey-200"
                >
                  {loading && <Spinner />}
                  Iniciar
                </button>
              </div>
              <div className="text-xs text-center">
                Al darle click aceptas los{' '}
                <a
                  className="text-blue-400 decoration-blue-400 underline"
                  href="https://www.usealisto.com/terminos-y-condiciones"
                  target="_blank"
                  rel="noreferrer"
                >
                  términos y políticas
                </a>{' '}
                de uso
              </div>
              <div className="text-xs text-center" onClick={handleGoToLogin}>
                <span className="text-blue-400 decoration-blue-400 underline cursor-pointer">
                  ¿Ya tienes cuenta? Inicia sesión
                </span>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-10">
          <button
            className="text-sm text-gray-300 text-center bg-gray-900 rounded-xl py-2 px-8"
            onClick={handleGoToJoin}
          >
            Únete a tu equipo
          </button>
        </div>
        <span className={'text-sm text-gray-400 absolute right-1.5 bottom-1.5'}>v. {version}</span>
      </div>
    </Layout>
  );
};

export default Landing;
